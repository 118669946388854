import { Box, Typography, Link } from '@mui/material';
import React from 'react';
import './HomePage.css';

const HomePage = () => {
    const headings = [
        'Welcome to Yukthi SSH Key Management System',
        'This is a simple SSH Key Management System built using React and API Platform by',
        'Please login to continue',
        'Click on the Dashboard link in the navigation bar to view the dashboard'
    ];

    const userLoggedIn = localStorage.getItem('user_details');

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            }}
        >
            <Typography variant="h2" gutterBottom>{headings[0]}</Typography>
            <Typography variant="h5" gutterBottom>
                {headings[1]}{' '}
            </Typography>
            <Typography variant="h5" gutterBottom>
                <Link href="https://www.NekoNik.com" target="_blank" rel="noreferrer">
                    Neko Nik
                </Link>{' '}
                as a part of the{' '}
                <Link href="https://www.Yukthi.com" target="_blank" rel="noreferrer">
                    Yukthi
                </Link> project
            </Typography>
            <Typography variant='h5' mt={4}>
                {userLoggedIn ? headings[3] : headings[2]}
            </Typography>
        </Box>
    );
};

export default HomePage;
