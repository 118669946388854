import { Box, CardContent, Typography, Grid, Chip, Divider, Snackbar, Alert, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { login } from '../api/BaseCalls';

const UserDisplay = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const user_details = JSON.parse(localStorage.getItem('user_details'));
        if (!user_details) {
            window.location.href = '/';
        } else {
            setUserDetails(user_details);
        }
    }, []);

    const handleLoginRefresh = async () => {
        const baseAuthString = localStorage.getItem('auth_token');
        if (!baseAuthString) {
            alert('Login token not found. Please login again.');
            window.location.href = '/';
            return;
        }
        const [user_id, password] = atob(baseAuthString).split(':');

        try {
            setLoading(true);
            const user_details = await login(user_id, password);
            localStorage.setItem('user_details', JSON.stringify(user_details));
            // Expiry time is set to 1 hour from now
            localStorage.setItem('expiry_time', new Date().getTime() + (1 * 60 * 60 * 1000));
            alert(`User details refreshed successfully!\nWelcome back ${user_details.full_name}!`);
            window.location.href = '/dashboard';
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        setSnackbarMessage(`Copied: ${text}`);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    if (!userDetails) {
        return null;
    }

    return (
        <Box mt={4} p={2} className="box-71">
            <CardContent>
                <Typography variant="h4" component="div" gutterBottom>
                    User Details
                    <IconButton onClick={() => handleLoginRefresh()} disabled={loading} sx={{ float: 'right' }}><RefreshIcon /></IconButton>
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="body1"><strong>User Name:</strong> {userDetails.username}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="body1"><strong>Email:</strong> {userDetails.email}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="body1"><strong>Full Name:</strong> {userDetails.full_name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Created At:</strong> {userDetails.created_at} IST</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h5" component="div" gutterBottom>
                    SSH Access Matrices
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Available Servers:</strong></Typography>
                        {userDetails.ssh_access_matrices.available_servers.map((server, index) => (
                            <Chip
                                key={index}
                                label={server}
                                onClick={() => handleCopy(server)}
                                sx={{ 
                                    mr: 1, 
                                    mb: 1, 
                                    cursor: 'pointer', 
                                    '&:hover': { backgroundColor: 'primary.light' } 
                                }}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Available Users:</strong></Typography>
                        {userDetails.ssh_access_matrices.available_users.map((user, index) => (
                            <Chip
                                key={index}
                                label={user}
                                onClick={() => handleCopy(user)}
                                sx={{ 
                                    mr: 1, 
                                    mb: 1, 
                                    cursor: 'pointer', 
                                    '&:hover': { backgroundColor: 'primary.light' } 
                                }}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1"><strong>Max SSH Session Days:</strong> {userDetails.ssh_access_matrices.max_days}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default UserDisplay;
