import { Box, CardContent, Typography, FormControl, InputLabel, Select, MenuItem, Slider, Grid, Button, TextField, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Snackbar, Alert } from '@mui/material';
import React, { useState } from 'react';
import { signSshKey } from '../api/BaseCalls';
import CopyIcon from '@mui/icons-material/ContentCopy';

const SSHKeySign = () => {
    const user_details = JSON.parse(localStorage.getItem('user_details'));
    // {"username":"nikhil","email":"nikhil@yukthi.com","full_name":"Nikhil Raj","is_admin":true,"ssh_access_matrices":{"available_servers":["ssh.nekonik.com"],"available_users":["neko","cats","nik"], "max_days": 1},"created_at":"2024-06-22 12:58:34"}

    if (!user_details) {
        window.location.href = '/';
    }

    const { ssh_access_matrices: { available_servers } } = user_details;
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [selectedServer, setSelectedServer] = useState(available_servers[0]);
    const [days, setDays] = useState(1);
    const [sshKey, setSshKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [responseDialogOpen, setResponseDialogOpen] = useState(false);
    const [responseData, setResponseData] = useState(null);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleServerChange = (event) => {
        setSelectedServer(event.target.value);
    };

    const handleSliderChange = (event, newValue) => {
        setDays(newValue);
    };

    const handleKeyChange = (event) => {
        setSshKey(event.target.value);
    };

    const handleSubmit = async () => {
        if (sshKey.trim() === '') {
            alert('Please enter a valid SSH key');
            return;
        }
        if (!selectedServer || !available_servers.includes(selectedServer)) {
            alert('Please select a valid server, if servers are not available please contact the administrator to add servers');
            return;
        }

        setLoading(true);
        const data = {
            "host_name": selectedServer,
            "ssh_user_public_key": sshKey.trim(),
            "validity_period": days
        };
        try {
            const response_data = await signSshKey(data);
            setResponseData(response_data);
            setResponseDialogOpen(true);
        }
        catch (error) {
            alert(error);
        }
        finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setResponseDialogOpen(false);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(responseData.signed_key.trim());
        setSnackbarMessage(`Copied signed key to clipboard`);
        setSnackbarOpen(true);
    };

    return (
        <Box mt={4} p={2} className="box-71">
            <CardContent>
                <Typography variant="h4" component="div" gutterBottom>
                    SSH Key Sign
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    Configure your SSH key settings below.
                </Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="server-select-label">Select Server</InputLabel>
                            <Select
                                labelId="server-select-label"
                                value={selectedServer}
                                label="Select Server"
                                onChange={handleServerChange}
                                disabled={loading}
                            >
                                {available_servers.map((server, index) => (
                                    <MenuItem key={index} value={server}>
                                        {server}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography id="days-slider" gutterBottom>
                            Select Duration (days) : {days}
                        </Typography>
                        <Slider
                            value={days}
                            min={1}
                            max={7}
                            step={1}
                            onChange={handleSliderChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="days-slider"
                            disabled={loading}
                        />
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <TextField
                        placeholder="Enter your public SSH key here (e.g. ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQC...)"
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={sshKey}
                        onChange={handleKeyChange}
                        sx={{ borderRadius: 4, '& .MuiOutlinedInput-root': { borderRadius: 4 } }}
                        disabled={loading}
                    />
                </Box>
                <Box mt={4} display="flex" justifyContent="center">
                    <button class="button-85" onClick={handleSubmit} disabled={loading}>
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign SSH Key'}
                    </button>
                </Box>
            </CardContent>

            <Dialog open={responseDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>SSH Key Signed Successfully</DialogTitle>
                <DialogContent dividers>
                    <Typography variant="body1"><strong>Signed Key:</strong></Typography>
                    <Box display="flex" alignItems="center" mb={2}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            value={responseData?.signed_key}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <IconButton onClick={handleCopy} sx={{ ml: 1 }}>
                            <CopyIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="body1"><strong>Validity Period:</strong> {responseData?.validity_period} day(s)</Typography>
                    <Typography variant="body1"><strong>Allowed Hosts:</strong> {responseData?.allowed_hosts.join(', ')}</Typography>
                    <Typography variant="body1"><strong>Allowed Users:</strong> {responseData?.allowed_users.join(', ')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default SSHKeySign;
