import React, { useState } from 'react'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography, Box } from '@mui/material'
import { changePassword } from '../api/BaseCalls'

const UpdatePasswd = () => {
    const [newPasswordA, setNewPasswordA] = useState('')
    const [newPasswordB, setNewPasswordB] = useState('')
    const [open, setOpen] = useState(false)

    const handleUpdatePasswd = async () => {
        try {
            await changePassword(newPasswordA)
            alert('Password updated successfully')
            // clear all local storage and navigate to / to force re-login
            localStorage.clear()
            window.location.href = '/'
            localStorage.clear()

            handleClose()
        } catch (error) {
            alert(error.message)
        }
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setNewPasswordA('')
        setNewPasswordB('')
    }

    const getPasswordStrength = (password) => {
        let strength = 0
        if (password.length >= 8) strength += 20
        if (password.match(/[A-Z]/)) strength += 20
        if (password.match(/[a-z]/)) strength += 20
        if (password.match(/[0-9]/)) strength += 20
        if (password.match(/[@$!%*?&#]/)) strength += 20
        return strength
    }

    const passwordStrength = getPasswordStrength(newPasswordA)
    const isFormValid = newPasswordA === newPasswordB && passwordStrength === 100

    return (
        <div style={{ textAlign: 'right', marginRight: '1rem', marginTop: '1rem' }}>
            <button className="button-89" onClick={handleClickOpen}>
                Update Password
            </button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Update Password</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="New Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={newPasswordA}
                        onChange={(e) => setNewPasswordA(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Confirm New Password"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={newPasswordB}
                        onChange={(e) => setNewPasswordB(e.target.value)}
                    />
                    <Box mt={2}>
                        <Typography>Password Strength</Typography>
                        <LinearProgress variant="determinate" value={passwordStrength} />
                        <Typography variant="caption">{passwordStrength}%</Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="body2" color={newPasswordA.length >= 8 ? 'green' : 'red'}>
                            - At least 8 characters
                        </Typography>
                        <Typography variant="body2" color={newPasswordA.match(/[A-Z]/) ? 'green' : 'red'}>
                            - At least one uppercase letter
                        </Typography>
                        <Typography variant="body2" color={newPasswordA.match(/[a-z]/) ? 'green' : 'red'}>
                            - At least one lowercase letter
                        </Typography>
                        <Typography variant="body2" color={newPasswordA.match(/[0-9]/) ? 'green' : 'red'}>
                            - At least one digit
                        </Typography>
                        <Typography variant="body2" color={newPasswordA.match(/[@$!%*?&#]/) ? 'green' : 'red'}>
                            - At least one special character (@$!%*?&#)
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdatePasswd} color="primary" disabled={!isFormValid}>
                        Update Password
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UpdatePasswd
