import { Box } from '@mui/material'
import React from 'react'
import UserDisplay from '../components/UserDisplay'
import SSHKeySign from '../components/SSHKeySign'
import UpdatePasswd from '../components/UpdatePasswd'

const Dashboard = () => {
    return (
        <Box sx={{ marginBottom: '4rem' }}>
            <UserDisplay />
            <UpdatePasswd />
            <SSHKeySign />
        </Box>
    )
}

export default Dashboard
