import axios from 'axios';


const API_BASE_URL = 'https://ssh-api.yukthi.net';


const login = async (username, password) => {
    const url = `${API_BASE_URL}/users/info`;
    try {
        const response = await axios.get(url, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Basic ${btoa(`${username}:${password}`)}`
            }
        });
        localStorage.setItem('auth_token', btoa(`${username}:${password}`));
        return response.data;
    } catch (error) {
        console.error('Login API call failed:', error.response.data);
        throw new Error(`Login failed: ${JSON.stringify(error.response)}`);
    }
};


const signSshKey = async (data) => {
    const url = `${API_BASE_URL}/ssh/sign`;
    try {
        const response = await axios.post(url, data, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Basic ${localStorage.getItem('auth_token')}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Sign SSH Key API call failed:', error.response.data);
        throw new Error(`Sign SSH Key failed: ${JSON.stringify(error.response)}`);
    }
}


const changePassword = async (newPassword) => {
    const url = `${API_BASE_URL}/users/password`;
    try {
        const response = await axios.put(url, { new_password: newPassword }, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Basic ${localStorage.getItem('auth_token')}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Change Password API call failed:', error.response.data);
        throw new Error(`Change Password failed: ${JSON.stringify(error.response)}`);
    }
}


export { API_BASE_URL, login, signSshKey, changePassword };
