import { Button } from '@mui/base';
import React from 'react';

const NotFound = () => {
  // Set 404 status code
  React.useEffect(() => {
    document.title = '404 - Not Found';
    return () => {
      document.title = 'Your Website Title'; // Reset title if component unmounts
    };
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404 - Not Found</h1>
      <p style={styles.message}>Sorry, the page you are looking for does not exist.</p>
      <Button variant="contained" color="primary" href="/"> Go to Home </Button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
    backgroundColor: '#f8f9fa', // Change to your preferred background color
    color: '#343a40', // Change to your preferred text color
    fontFamily: 'Arial, sans-serif', // Change to your preferred font
  },
  title: {
    fontSize: '3rem',
    marginBottom: '1rem',
  },
  message: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
};

export default NotFound;
